.text-breadcrumb {
	/* TextBreadcrumb */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	/* identical to box height */

	display: flex;

	color: #000000;
}

.text-breadcrumb-category {
	font-family: RocknRoll One;
	font-style: normal;
	font-weight: normal;
	display: flex;
	height: fit-content;

	color: rgba(0, 0, 0, 0.3);
}

.text-page-name-title {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	background: transparent;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
		1px 1px 0 #000;

	color: #ffffff;
}

.text-page-title {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	background: transparent;

	color: #524d4d;
}

.text-second-title {
	/* Text_Header_2 */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	text-align: center;

	/* text_color_3 */

	color: #524d4d;
}

.text-main-page1 {
	font-family: 'Roboto';
	/* text_color_3 */

	color: #52514d;
}

.text-notification {
	/* Text_Notification_1 */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	text-align: center;

	color: #000000;
}

.text-notification-link {
	/* Text_Notification_1 */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	text-decoration-line: underline;
	margin-left: 0.2rem;

	color: #000000;
}

.text-login {
	/* Text_HeaderPrincipal */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 800;
	display: flex;
	align-items: center;
	text-align: center;
	text-indent: 6px;

	/* color_3 */

	color: #ffffff;
}

.text-login-field {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 800;
	display: flex;
	align-items: left;
	text-align: left;
	text-indent: 6px;
}

.text-row-1 {
	font-family: 'Roboto';
	font-style: normal;

	color: #524d4d;
}

.text-row-2 {
	/* Team_text_2 */

	font-family: 'Roboto';
	font-style: normal;
	display: flex;
	align-items: center;

	/* text_color_3 */

	color: #524d4d;
}

.text-header-button-1 {
	/* HeaderButton */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 800;
	align-items: center;
	text-align: center;
	text-indent: 6px;

	color: #ffffff;
}

.text-header-button-2 {
	/* HeaderButton_Alt */

	font-family: Ruluko;
	font-style: normal;
	font-weight: normal;
	/* text_color_3 */

	color: #524d4d;
}

.text-round-title {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 28px;
	line-height: 33px;
	/* identical to box height */

	display: flex;
	align-items: center;

	color: #524d4d;
}

.text-match-info-1 {
	/* Team_Text_1 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	justify-content: flex-end;
	text-align: right;
	margin-right: 0.5rem;
	align-items: center;
	padding-top: 0.25rem;

	/* text_color_3 */

	color: #524d4d;
}

.text-match-info-2 {
	/* Team_Text_2 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	text-align: left;
	background: transparent;
	align-items: center;
	padding-top: 0.25rem;

	/* text_color_2 */

	color: #524d4d;
}

.text-match-info-3 {
	/* Team_text_3 */

	font-family: Roboto;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	display: flex;
	align-items: center;
	text-align: center;

	/* text_color_3 */

	color: #524d4d;
}

.text-match-info-4 {
	/* Text_Counter_3 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-align: center;

	color: #000000;
}

.text-match-info-5 {
	/* Team_Text_1 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	text-align: left;
	background: transparent;
	padding-top: 0.25rem;

	/* text_color_3 */

	color: #524d4d;
}

.text-match-score {
	font-family: Roboto;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #000000;
	/* TeamSetScore */

	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
}

.text-match-penalties-score {
	font-family: Roboto;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #000000;
}

.text-match-sets-won-container {
	background: #ffffff;
}

.text-match-sets-won-container-big {
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
}

.text-match-sets-won-amount {
	/* Text_Counter_2 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 44px;
	line-height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #000000;
}

.text-match-sets-won-amount-big {
	/* Text_Counter_1 */

	font-family: Roboto;
	font-style: normal;
	font-size: 96px;
	line-height: 112px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #000000;
}

.text-match-sets-won-title {
	/* Text_Counter_3 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #000000;

	margin-top: 0.5rem;
}

.text-match-sets-won-title-big {
	/* Text_Counter_3 */

	font-family: Roboto;
	font-style: normal;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #000000;
}

.text-set-resume-score {
	/* Text_Counter_2 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 44px;
	line-height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #000000;

	width: 4rem;
	height: 2.5rem;
	padding-bottom: 0rem;
}

.text-period-resume-score {
	/* Text_Counter_2 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #000000;

	width: 4rem;
	height: 2.5rem;
	padding-bottom: 0rem;
}

.text-set-resume {
	/* Text_Counter_3 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	/* identical to box height */

	display: flex;
	justify-content: center;
	text-align: center;

	color: #000000;
}

.font-roboto {
	font-family: 'Roboto';
}

.font-1 {
	/* TeamInfo */

	font-family: 'RopaSans';
	font-style: normal;
	font-weight: normal;
	align-items: center;

	/* text_color_3 */

	color: #524d4d;
}

.font-place {
	font-family: 'fantasy';
	font-style: normal;
	display: flex;
	align-items: center;

	color: black;
	-webkit-text-fill-color: white; /* Will override color (regardless of order) */
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: black;
}

.text-match-competitor {
	/* Text_Header_1 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	justify-content: center;

	/* text_color_3 */

	color: #524d4d;
}

.text-match-side {
	font-family: 'Roboto';
	font-style: normal;
	display: flex;

	color: #000000;
}

.text-match-1 {
	/* Text_Header_2 */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	line-height: 35px;
	display: flex;
	align-items: center;
	text-align: center;

	/* text_color_3 */

	color: #524d4d;
}

.text-match-time {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #000000;
	/* TeamSetScore */

	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
}

.text-match-events-title {
	font-family: 'Roboto';
	font-style: normal;
	align-items: center;
	text-align: center;

	background-color: #000000;
	color: #ffffff;
}

.text-match-events-content {
	font-family: 'Roboto';
	font-style: normal;
	align-items: center;
	text-align: right;

	color: #000000;
}

.text-match-period {
	font-family: 'Roboto';
	font-style: normal;
	align-items: center;

	color: #000000;
}

.text-team {
	/* Team_Text_1 */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;

	/* text_color_3 */

	color: #524d4d;
}

.text-team-2 {
	/* Team_text_2 */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.text-user {
	/* Team_text_2 */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	display: flex;
	align-items: center;
}

text-team {
	/* Team_text_2 */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	display: flex;
	align-items: center;
	text-align: center;
}

.profile-panel-button-1 {
	/* HeaderButton */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	text-align: center;
	word-break: break-word;

	color: #ffffff;
}

.profile-panel-button-2 {
	/* HeaderButton_Alt */

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	/* text_color_3 */

	color: #524d4d;
	&:hover {
		background-color: #e5e5e5;
	}
}

.text-home-1 {
	font-family: 'Roboto';
	color: #000000;
}

.text-home-2 {
	font-family: 'Ubuntu';
	color: #524d4d;
	font-weight: bold;
}

.text-home-3 {
	font-family: 'Roboto';
	color: #211f1f;
}

.text-home-4 {
	font-family: sans-serif;
	color: #ffffff;
	font-weight: 600;

	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.text-home-5 {
	font-family: 'Ubuntu';
	color: #000000;
	font-weight: bold;
}

.text-home-6 {
	font-family: 'Roboto';
	color: #000000;
}

.text-home-7 {
	font-family: 'Roboto';
	color: #ffffff;
}

.text-home-8 {
	font-family: 'Roboto';
	color: #f3df75;
}

@font-face {
	font-family: 'RopaSans';
	src: local('RopaSans'), url(../fonts/RopaSans-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'RocknRoll One';
	src: local('RocknRoll One'),
		url(../fonts/RocknRollOne-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto'),
		url(../fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Roboto Condensed';
	src: local('Roboto Condensed'),
		url(../fonts/Roboto/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Ubuntu';
	src: local('Ubuntu'), url(../fonts/Ubuntu/Ubuntu-R.ttf) format('truetype');
}
