@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-size: base;
	background-color: #e5e5e5;
}

.h-1-10 {
	height: 10%;
}

.w-9-10 {
	width: 90%;
}

.w-1\/4 {
	width: 25%;
}

.max-h-8 {
	max-height: 2rem;
}

.w-136 {
	width: 34rem; /* 34 x 16 = 544px */
}

.h-200 {
	height: 50rem; /* 50 x 16 = 800px */
}

.h-216 {
	height: 54rem; /* 60 x 16 = 864px */
}

.col-span-12 {
	grid-column: span 12 / span 12;
}
.col-span-11 {
	grid-column: span 11 / span 11;
}
.col-span-10 {
	grid-column: span 10 / span 10;
}
.col-span-9 {
	grid-column: span 9 / span 9;
}
.col-span-8 {
	grid-column: span 8 / span 8;
}
.col-span-7 {
	grid-column: span 7 / span 7;
}
.col-span-6 {
	grid-column: span 6 / span 6;
}
.col-span-5 {
	grid-column: span 5 / span 5;
}
.col-span-4 {
	grid-column: span 4 / span 4;
}
.col-span-3 {
	grid-column: span 3 / span 3;
}
.col-span-2 {
	grid-column: span 2 / span 2;
}

.grid-cols-11 {
	grid-template-columns: repeat(11, minmax(0, 1fr));
}
.grid-cols-10 {
	grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid-cols-9 {
	grid-template-columns: repeat(9, minmax(0, 1fr));
}
.grid-cols-8 {
	grid-template-columns: repeat(8, minmax(0, 1fr));
}
.grid-cols-7 {
	grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-cols-6 {
	grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-5 {
	grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-4 {
	grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-3 {
	grid-template-columns: repeat(3, minmax(0, 1fr));
}
