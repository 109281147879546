@mixin xs2 {
  @media only screen and (min-width: 400px) {
    @content;
  }
}

.grid-cols-10--2xs-grid-cols-12 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
  @include xs2 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
.grid-cols-8--2xs-grid-cols-10 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
  @include xs2 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

.h-112 {
  height: 28rem;
}
