// Responsive mixins
// Bootstrap breakpoints

@mixin sm {
	@media only screen and (min-width: 640px) {
		@content;
	}
}

@mixin md {
	@media only screen and (min-width: 768px) {
		@content;
	}
}

@mixin lg {
	@media only screen and (min-width: 1024px) {
		@content;
	}
}

@mixin xl {
	@media only screen and (min-width: 1280px) {
		@content;
	}
}

@mixin \2xl {
	@media only screen and (min-width: 1536px) {
		@content;
	}
}

.button-expand-collapse-1 {
	border: 0.8px solid #000000;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 32px;
	padding: 0.5rem;
}

.button-close-2 {
	border: 0.8px solid #000000;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 32px;
	padding: 0.1rem;
}

.button-up-down-1 {
	border: 0.8px solid #000000;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 32px;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.button-1 {
	background-color: #04859d;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
	border-radius: 32px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	/* or 133% */

	display: flex;
	align-items: center;
	text-align: center;

	color: #ffffff;

	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;

	cursor: pointer;
}
.button-1[disabled] {
	background-color: #c9cdce;
	cursor: default;
}

.button-2 {
	background: #07bbdb;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
	border-radius: 6px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	/* or 133% */

	display: flex;
	align-items: center;
	text-align: center;

	color: #ffffff;

	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
}

.button-2-disabled {
	background: #b7b7b7;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
	border-radius: 6px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	/* or 133% */

	display: flex;
	align-items: center;
	text-align: center;

	color: black;

	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
}

.button-3 {
	background: #e9e6e6;
	border-radius: 5px;

	font-family: 'Roboto';
	font-style: normal;
	/* identical to box height */

	display: flex;
	align-items: center;
	text-align: center;

	color: #524d4d;

	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;

	cursor: pointer;

	&:active,
	&.active {
		background: #b5b3b3;
	}
}
.button-3[disabled] {
	background-color: lightgrey;
	cursor: default;
	opacity: 0.5;
}

.button-cancel {
	background: transparent;
	border-radius: 32px;
	border: 1px solid #04859d;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	/* or 133% */

	display: flex;
	align-items: center;
	text-align: center;

	color: #000;

	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.button-delete {
	background: transparent;
	border-radius: 32px;
	border: 1px solid #d80b0b;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	/* or 133% */

	display: flex;
	align-items: center;
	text-align: center;

	color: #d80b0b;

	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.button-sign-out {
	background-color: #d80b0b;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
	border-radius: 32px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;

	display: flex;
	align-items: center;
	text-align: center;

	color: #ffffff;

	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.mini-button-1 {
	background: #f79605;
	border-radius: 8px;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	text-align: center;

	/* text_color_4 */

	color: #ffffff;

	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}

.mini-button-1[disabled] {
	background-color: #afaca7;
	cursor: default;
}

.pagination-button-1 {
	border-radius: 8px;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	text-align: center;

	/* text_color_4 */

	color: #504d4d;

	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;

	&.disabled {
		background-color: #b9b7b5;
		cursor: default;
		color: #4e4e4e;
	}
}

.mini-button-2 {
	background: #f79605;
	border-radius: 8px;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	text-align: center;

	/* text_color_4 */

	color: #ffffff;

	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.mini-button-2-cancel {
	background: transparent;
	border-radius: 8px;
	border-style: solid;
	border-width: 1px;
	border-color: #000;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	text-align: center;

	/* text_color_4 */

	color: #000;

	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.button-notification-1 {
	background: #04859d;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
	border-radius: 32px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	/* or 133% */

	display: flex;
	align-items: center;
	text-align: center;

	color: #ffffff;

	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.button-notification-2 {
	background: #ebebeb;
	border-radius: 32px;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	text-align: center;

	/* text_color_4 */

	color: #524d4d;

	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.button-login-1 {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	/* or 106% */

	display: flex;
	align-items: center;
	text-align: center;
	outline: none;
	&:focus {
		outline: 2px solid black;
		opacity: 0.8;
	}
	/* color_3 */

	color: #ffffff;
}
.button-login-1[disabled] {
	background-color: #c9cdce;
	cursor: default;
}
.tab-selected-button {
	/* Text_Header_2 */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	display: flex;
	align-items: center;
	text-align: center;

	padding-top: 0.6rem;
	padding-bottom: 0.5rem;
	color: #000000;
	background-color: white;
}

.tab-unselected-button {
	/* Text_Header_2 */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	display: flex;
	align-items: center;
	text-align: center;

	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #524d4d;
	background-color: #c4c4c4;
}

.button-link-black,
.button-link {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	display: flex;
	align-items: center;
	text-align: center;

	color: #000000;
}

.button-link {
	color: #069;
	text-decoration: underline;
}

.gg-button {
	background-color: #f2360d !important;
	font-family: 'Roboto' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 0.875rem;
	line-height: 1.25rem;
	display: flex !important;
	align-items: center !important;
	text-align: center !important;
	outline: none !important;
	color: #ffffff !important;
	justify-content: center !important;
	padding-bottom: 4px !important;
	border: 0 !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	@include md {
		font-size: 1.125rem !important;
		line-height: 1.75rem !important;
	}
}
