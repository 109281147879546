.color-bg-1 {
  background-color: #000;
}

.color-bg-2 {
  background-color: #015666;
}

.color-bg-3 {
  background-color: #ffffff !important;
}

.color-bg-4 {
  background-color: #f69605;
}

.color-bg-5 {
  background: rgba(55, 182, 206, 0.5);
}

.color-bg-6 {
  background: rgba(4, 133, 157, 0.2);
}

.color-bg-7 {
  background-color: #84004d;
}

.color-bg-8 {
  background-color: #c4c4c4 !important;
}

.color-bg-9 {
  background-color: #04859d;
}

.color-bg-10 {
  background-color: #d9d9d9;
}

.color-bg-11 {
  background-color: #e58227;
}

.color-bg-12 {
  background-color: #36b2ca;
}

.color-bg-13 {
  background-color: #0066b3;
}

.color-bg-deleted {
  background-color: #ff000046 !important;
}

.color-bg-whatsapp {
  background-color: #25d366;
}

.color-2 {
  color: #015666;
}

.color-4 {
  color: #f69605;
}

.color-5 {
  color: rgba(55, 182, 206, 0.5);
}

.color-7 {
  color: #84004d;
}

.color-bg-2-alt {
  background: rgba(4, 133, 157, 0.6);
}

.color-bg-2-alt-c {
  background-color: #a6dbe5;
}

.color-text-1 {
  color: #524d4d;
}

.color-bg-6-alt {
  background-color: #a6dbe6;
  &:hover {
    background-color: #5ac4d8;
  }
}

.color-bg-no-match {
  background-color: #c1c1c1;
}

.color-bg-table-header {
  background-color: #c1c1c1;
}

.color-bg-match {
  background-color: #e5e5e5;
}

.color-bg-user {
  background-color: #e5e5e5;
}

.color-bg-team {
  background-color: #dbe9e9;
}

.color-bg-field {
  background-color: #dbe9e9;
}

.color-bg-place {
  background-color: #dbe9e9;
}

.color-bg-match {
  background-color: #dbe9e9;
}

.color-bg-tournament {
  background-color: #dbe9e9;
}

.color-bg-user-pending {
  background-color: #5ac4d8;
}

.color-bg-user-rejected {
  background-color: #ec1f1f8f;
}

.color-user-pending {
  color: #5ac4d8 !important;
}

.color-user-rejected {
  color: #ec1f1f8f !important;
}

.color-user-invited {
  color: #5ac4d8 !important;
}

.color-user-asked {
  color: #5ac4d8 !important;
}

.color-user-accepted {
  color: black !important;
}

.row-color-1 {
  background: #e5e5e5;
}

.row-color-2 {
  background: #ffffff;
}

.fb-color {
  background-color: #4267b2;
}
